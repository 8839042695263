import React from "react";
import ModalBasic from "../../component/ModalBasic";

const SendClarifications = (props) => {
    return (
        <ModalBasic
            id="send-payment-link-modal"
            modalOpen={props.clarificationModal}
            setModalOpen={props.setOpenModal}
            title="Clarification Detail"
        // noCloseIcon={true}
        >
            <div className="pb-6 px-7 mt-2">
                <div>
                    {/* <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                    >
                        Company Name <span className="text-[#c00000]">*</span>
                    </label> */}
                    <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="clarification"
                        onChange={(e) => props.handleChangeClarificationDetails(e)}
                        value={props.clarification}
                    // onChange={(event) => props.handleChange(event)}
                    // onBlur={() => props.validateField("companyName")}
                    />
                </div>
                <div className="text-center">
                    <button
                        className="btn btn-blue text-base text-[#fff] font-bold mt-8"
                        onClick={() => props.getClarificationDetails()}
                    >
                        OK
                    </button>
                </div>
            </div >
        </ModalBasic>
    );
}
export default SendClarifications;